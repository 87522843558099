/** @format */


.sign-up .x-v-AI {
  color: transparent;
  font-family: "Outfit-SemiBold", Helvetica;
  font-size: 3vw;
  font-weight: 600;
  letter-spacing: 0;
  white-space: nowrap;
}

.sign-up .text-wrapper {
  color: #ffffff;
}

.sign-up .span {
  color: #ffc54f;
}

.sign-up .frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 50vw;
  border-width: 0px;
  margin: 0 11vw;
}

.sign-up .create-new-account {
  color: var(--colorswhitewhite);
  font-family: "Outfit-SemiBold", Helvetica;
  font-size: 3.5vh;
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin: 0 0 3vh 0;
}

.sign-up .buttons-2 {
  align-items: center;
  border: 1px solid;
  border-color: var(--colorswhitewhite);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  padding: 2vh 1vw;
  position: relative;
  width: 22vw;
}

.sign-up .text-wrapper-2 {
  color: var(--colorswhitewhite);
  font-family: var(--button-text-button-text-normal-font-family);
  font-size: var(--button-text-button-text-normal-font-size);
  font-style: var(--button-text-button-text-normal-font-style);
  font-weight: var(--button-text-button-text-normal-font-weight);
  letter-spacing: var(--button-text-button-text-normal-letter-spacing);
  line-height: var(--button-text-button-text-normal-line-height);
  text-align: center;
  width: 60vw;
}

.sign-up .google-g-logo {
  height: 25px;
  object-fit: cover;
  position: relative;
  width: 25px;
}

.sign-up .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5vh;
  justify-content: center;
  position: relative;
  margin: 5vh 0 0 0;
}

.sign-up .frame-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  position: relative;
  width: 45vw;
}

.sign-up .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
}

.sign-up .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
}

.sign-up .fields-3 {
  width: 19vw;
  height: 3vh;
  padding: 8px 20px 8px 20px;
  border-width: 0px 0px 1px 0px;
  gap: 10px;
  background-color: #1b1e28;
  color: #9c9fa7;
}

.sign-up .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  position: relative;
}

.sign-up .frame-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
}
.sign-up .frame-8 {
  align-items: center;
  flex-direction: row;
  flex: 0 0 auto;
  gap: 40px;
  display: flex;
}
.sign-up .content {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 28px;
  position: relative;
  width: 45vw;
}

.sign-up .elements-text-field-instance {
  flex: 0 0 auto !important;
}

.sign-up .title-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.sign-up .p {
  color: var(--colorswhitewhite);
}
.sign-up .desktop-p {
  color: var(--colorswhitewhite);
  display: inline-block;
}
.sign-up .mobile-p {
  color: var(--colorswhitewhite);
  display: none;
}

.sign-up .text-wrapper-3 {
  color: #ffffff;
  font-family: var(--body-body-2-font-family);
  font-size: var(--body-body-2-font-size);
  font-style: var(--body-body-2-font-style);
  font-weight: var(--body-body-2-font-weight);
  letter-spacing: var(--body-body-2-letter-spacing);
  line-height: var(--body-body-2-line-height);
}

.sign-up .text-wrapper-4 {
  font-family: "Outfit-SemiBold", Helvetica;
  font-weight: 600;
}

.sign-up .text-elements-3 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.sign-up .buttons-instance {
  display: flex !important;
  width: 389px !important;
}

.sign-up .button-container {
  flex-direction: column;
}

.sign-up .login-button {
  height: 6vh;
  width: 22vw;
  border-radius: 15px;
  background-color: #ffdc95;
}
.sign-up .signup-button {
  height: 6vh;
  width: 22vw;
  border-radius: 15px;
  background-color: #1b1e28;
  color: #ffffff;
  border-color: #ffffff;
  border-width: 1px;
}

.sign-up .dropdown {
  width: 21vw;
  height: 4vh;
  background-color: #1b1e28;
  padding: 8px 12px 8px 16px;
  border-width: 0px 0px 1px 0px;
  border-radius: 1px;
  gap: 12px;
  color: #9c9fa7;
}
.sign-up .checkbox {
  appearance: none;
  align-items: center;
  justify-content: center;
  width: 25px !important;
  height: 27px !important;
  padding: 6px;
  border: 2px solid #ffc54f;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
.sign-up .checkbox:checked::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}
.signup-button .desktop-text {
  display: inline-block;
}
.signup-button .mobile-text {
  display: none;
}

/* ------------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 768px) {
  .sign-up {
    align-items: center;
    align-content: center;
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }
  .sign-up .frame {
    width: 90vw;
    height: 100vh;
    margin: 0 auto;
  }
  .sign-up .frame-2 {
    width: 50vw;
    height: 85vh;
    align-items: center;
    margin: 0 0 0 0;
  }
  .sign-up .frame-3 {
    width: 80vw;
    height: 70vh;
    gap: 10px;
    align-items: center;
    margin: 2vh auto;
  }
  .sign-up .frame-4 {
    flex-direction: column;
    gap: 20px;
  }
  .sign-up .frame-5 {
    flex-direction: column;
    gap: 20px;
  }
  .sign-up .frame-7 {
    flex-direction: column;
    gap: 20px;
  }
  .sign-up .frame-6 {
    align-items: center;
    margin: 0 auto;
    height: vh;
    gap: 1vh;
  }
  .sign-up .frame-8 {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
  }
  .sign-up .x-v-AI {
    font-size: 10vw;
    align-self: center;
  }

  .sign-up .dropdown {
    width: 75vw;
    height: 6vh;
    padding: 8px 12px 8px 16px;
    border-width: 0px 0px 1px 0px;
    border-radius: 1px;
    gap: 12px;
    color: #9c9fa7;
  }
  .sign-up .fields-3 {
    width: 70vw;
    height: 4vh;
    padding: 1vh 2vw;
    border-width: 0px 0px 1px 0px;
    gap: 10px;
    background-color: #1b1e28;
    color: #9c9fa7;
  }
  .sign-up .button-container {
    width: 75vw;
    height: 11vh;
  }
  .sign-up .login-button {
    height: 7vh;
    width: 35vw;
    border-radius: 15px;
    border-width: 1px;
    background-color: #ffdc95;
  }
  .sign-up .signup-button {
    height: 7vh;
    width: 35vw;
    border-radius: 15px;
    background-color: #1b1e28;
    color: #ffffff;
    border-color: #ffffff;
    border-width: 1px;
  }
  .sign-up .buttons-2 {
    align-items: center;
    background-color: var(--colorsdarkneutral-500);
    border: 1px solid;
    border-color: var(--colorswhitewhite);
    border-radius: 15px;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 2vh 1vw;
    position: relative;
    width: 63vw;
  }
  .sign-up .content {
    align-items: center;
    height: 5vh;
    position: relative;
    width: 80vw;
    align-self: center;
  }

  .sign-up .checkbox {
    appearance: none;
    width: 25px !important;
    height: 25px;
    border: 2px solid #ffc54f;
    border-radius: 8px;
    cursor: pointer;
  }

  .signup-button .desktop-text {
    display: none;
  }
  .signup-button .mobile-text {
    display: inline-block;
  }
  .sign-up .form {
    align-items: center;
    width: 30vw;
    justify-content: center;
  }
  .signup-button .desktop-text {
    display: none;
  }
  .signup-button .mobile-text {
    display: inline-block;
  }
  .sign-up .desktop-p {
    color: var(--colorswhitewhite);
    display: none;
  }
  .sign-up .mobile-p {
    color: var(--colorswhitewhite);
    display: inline-block;
  }
}
