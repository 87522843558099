/**
 * src/styles.css
 *
 * @format
 */

.message-banner {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #f44336;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.message-banner.visible {
  opacity: 1;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.brand {
  color: #101828;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Gilroy;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 81.25% */
}
.custom-table th {
  background-color: lightgray;
}

.row-separator {
  border-top: 1px solid black;
}

.App {
  background-color: #1b1e28;
  background-color: #ffffff;
}
