/** @format */



.sign-in .x-v-AI {
  color: transparent;
  font-family: "Outfit-SemiBold", Helvetica;
  font-size: 3vw;
  font-weight: 600;
  letter-spacing: 0;
  white-space: nowrap;
}

.sign-in .text-wrapper {
  color: #ffffff;
}

.sign-in .span {
  color: #ffc54f;
}

.sign-in .frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  height: 90vh;
  justify-content: center;
  position: relative;
  width: 50vw;
  border-width: 0px;
  margin: 0 10vw;
}

.sign-in .sign-in-text {
  color: var(--colorswhitewhite);
  font-family: "Outfit-SemiBold", Helvetica;
  font-size: 4vh;
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin: 0 0 4vh 0;
}

.sign-in .buttons-2 {
  align-items: center;
  border: 1px solid;
  border-color: var(--colorswhitewhite);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  padding: 2vh 1vw;
  position: relative;
  width: 22vw;
}

.sign-in .text-wrapper-2 {
  color: var(--colorswhitewhite);
  font-family: var(--button-text-button-text-normal-font-family);
  font-size: var(--button-text-button-text-normal-font-size);
  font-style: var(--button-text-button-text-normal-font-style);
  font-weight: var(--button-text-button-text-normal-font-weight);
  letter-spacing: var(--button-text-button-text-normal-letter-spacing);
  line-height: var(--button-text-button-text-normal-line-height);
  text-align: center;
  width: 60vw;
}

.sign-in .google-g-logo {
  height: 25px;
  object-fit: cover;
  position: relative;
  width: 25px;
}

.sign-in .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5vh;
  justify-content: center;
  position: relative;
  margin: 5vh 0 0 0;
}

.sign-in .form {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5vh;
  justify-content: center;
  position: relative;

}

.sign-in .frame-3 {
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
  width: 45vw;
}

.sign-in .fields-instance {
  width: 21vw;
  height: 5vh;
  gap: 10px;
  border-width: 0 0 1px 0;
  background-color: #1b1e28;
  color: #9c9fa7;
}

.sign-in .design-component-instance-node {
  flex: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: -2px !important;
  margin-top: -2px !important;
  width: unset !important;
}

.sign-in .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
}

.sign-in .content {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 28px;
  position: relative;
  width: 20vw;
}

.sign-in .text-elements-2 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.sign-in .sign-in .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
}

.sign-in .p {
  color: #ffffff;
}

.sign-in .button-container {
  display: flex;
  flex-direction: row;

  width: 45vw;
  justify-content: space-around;
  align-items: center;
}

.sign-in .login-button {
  height: 6vh;
  width: 22vw;
  border-radius: 15px;
  background-color: #ffdc95;
}
.sign-in .signup-button {
  height: 6vh;
  width: 22vw;
  border-radius: 15px;
  background-color: #1b1e28;
  color: #ffffff;
  border-color: #ffffff;
  border-width: 1px;
}

.signup-button .desktop-text {
  display: inline-block;
}
.signup-button .mobile-text {
  display: none;
}

.sign-in .checkbox {
  appearance: none;
  align-items: center;
  justify-content: center;
  width: 25px !important;
  height: 27px !important;
  padding: 6px;
  border: 2px solid #ffc54f;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.sign-in .checkbox:checked::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}

/*---------------------------------------------------------Phone desing---------------------------------------------------------------------------------   */

@media (max-width: 768px) {
  .sign-in {
    align-items: center;
    align-content: center;
    flex-direction: column;
  }
  .sign-in .frame {
    width: 40vw;
    height: 70vh;
  }
  .sign-in .frame-2 {
    width: 30vw;
  }
  .sign-in .x-v-AI {
    font-size: 10vw;
    align-self: center;
  }

  .sign-in .fields-instance {
    width: 70vw;
    height: 7vh;
    padding: 1vh 2vw;
    margin: 2vh 2vw;
    border-width: 0px 0px 1px 0px;
    gap: 10px;
    background-color: #1b1e28;
    color: #9c9fa7;
  }

  .sign-in .login-button {
    height: 7vh;
    width: 35vw;
    border-radius: 15px;
    background-color: #ffdc95;
  }
  .sign-in .signup-button {
    height: 7vh;
    width: 35vw;
    border-radius: 15px;
    background-color: #1b1e28;
    color: #ffffff;
    border-color: #ffffff;
    border-width: 1px;
  }
  .sign-in .buttons-2 {
    align-items: center;
    background-color: var(--colorsdarkneutral-500);
    border: 1px solid;
    border-color: var(--colorswhitewhite);
    border-radius: 15px;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 2vh 1vw;
    position: relative;
    width: 63vw;
  }
  .sign-in .content {
    align-items: center;
    height: 5vh;
    position: relative;
    width: 40vw;
  }
  .sign-in .frame-3 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    height: 25vh;
    width: 30vw;
  }
  .sign-in .checkbox {
    appearance: none;
    width: 25px;
    height: 25px;
    border: 2px solid #ffc54f;
    border-radius: 8px;
    cursor: pointer;
  }

  .signup-button .desktop-text {
    display: none;
  }
  .signup-button .mobile-text {
    display: inline-block;
  }
}

.sign-in .x-v-AI {
  color: transparent;
  font-family: "Outfit-SemiBold", Helvetica;
  font-size: 3vw;
  font-weight: 600;
  letter-spacing: 0;
  white-space: nowrap;
}

.sign-in .text-wrapper {
  color: #ffffff;
}

.sign-in .span {
  color: #ffc54f;
}

.sign-in .frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  height: 90vh;
  justify-content: center;
  position: relative;
  width: 50vw;
  border-width: 0px;
  margin: 0 10vw;
}

.sign-in .sign-in-text {
  color: var(--colorswhitewhite);
  font-family: "Outfit-SemiBold", Helvetica;
  font-size: 4vh;
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin: 0 0 4vh 0;
}

.sign-in .buttons-2 {
  align-items: center;
  background-color: var(--colorsdarkneutral-500);
  border: 1px solid;
  border-color: var(--colorswhitewhite);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  padding: 2vh 1vw;
  position: relative;
  width: 22vw;
}

.sign-in .text-wrapper-2 {
  color: var(--colorswhitewhite);
  font-family: var(--button-text-button-text-normal-font-family);
  font-size: var(--button-text-button-text-normal-font-size);
  font-style: var(--button-text-button-text-normal-font-style);
  font-weight: var(--button-text-button-text-normal-font-weight);
  letter-spacing: var(--button-text-button-text-normal-letter-spacing);
  line-height: var(--button-text-button-text-normal-line-height);
  text-align: center;
  width: 60vw;
}

.sign-in .google-g-logo {
  height: 25px;
  object-fit: cover;
  position: relative;
  width: 25px;
}

.sign-in .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5vh;
  justify-content: center;
  position: relative;
  margin: 5vh 0 0 0;
}

.sign-in .frame-3 {
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
  width: 45vw;
}

.sign-in .fields-instance {
  width: 21vw;
  height: 5vh;
  gap: 10px;
  border-width: 0 0 1px 0;
  background-color: #1b1e28;
  color: #9c9fa7;
}

.sign-in .design-component-instance-node {
  flex: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: -2px !important;
  margin-top: -2px !important;
  width: unset !important;
}

.sign-in .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
}

.sign-in .content {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 28px;
  position: relative;
  width: 20vw;
}

.sign-in .text-elements-2 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.sign-in .sign-in .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
}

.sign-in .p {
  color: #ffffff;
}

.sign-in .button-container {
  display: flex;
  flex-direction: row;
  width: 45vw;
  justify-content: space-around;
  align-items: center;
  height: 11vh;
}

.sign-in .login-button {
  height: 6vh;
  width: 22vw;
  border-radius: 15px;
  background-color: #ffdc95;
}
.sign-in .signup-button {
  height: 6vh;
  width: 22vw;
  border-radius: 15px;
  background-color: #1b1e28;
  color: #ffffff;
  border-color: #ffffff;
  border-width: 1px;
}

.signup-button .desktop-text {
  display: inline-block;
}
.signup-button .mobile-text {
  display: none;
}

.sign-in .checkbox {
  appearance: none;
  width: 1.5vw;
  height: 3vh;
  padding: 6px;
  border: 2px solid #ffc54f;
  border-radius: 8px;
  cursor: pointer;
}
.sign-in .checkbox:checked::before {
  content: "\2713";
  align-self: center;
  color: #ffffff;
}

/*---------------------------------------------------------Phone desing---------------------------------------------------------------------------------   */

@media (max-width: 768px) {
  .sign-in {
    align-items: center;
    align-content: center;
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }
  .sign-in .frame {
    width: 40vw;
    height: 80vh;
    align-items: center;
    padding: 0 0 10vh 0;
  }
  .sign-in .frame-2 {
    width: 30vw;
  }
  .sign-in .x-v-AI {
    font-size: 10vw;
    align-self: center;
  }

  .sign-in .fields-instance {
    width: 70vw;
    height: 7vh;
    padding: 1vh 2vw;
    margin: 2vh 2vw;
    border-width: 0px 0px 1px 0px;
    gap: 10px;
    background-color: #1b1e28;
    color: #9c9fa7;
  }
  .sign-in .button-container {
    width: 75vw;
    height: 11vh;
  }
  .sign-in .login-button {
    height: 7vh;
    width: 35vw;
    border-radius: 15px;
    background-color: #ffdc95;
  }
  .sign-in .signup-button {
    height: 7vh;
    width: 35vw;
    border-radius: 15px;
    background-color: #1b1e28;
    color: #ffffff;
    border-color: #ffffff;
    border-width: 1px;
  }
  .sign-in .buttons-2 {
    align-items: center;
    background-color: var(--colorsdarkneutral-500);
    border: 1px solid;
    border-color: var(--colorswhitewhite);
    border-radius: 15px;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 2vh 1vw;
    position: relative;
    width: 63vw;
  }
  .sign-in .content {
    align-items: center;
    height: 5vh;
    position: relative;
    width: 40vw;
  }
  .sign-in .frame-3 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    height: 25vh;
    width: 30vw;
  }
  .sign-in .checkbox {
    appearance: none;
    width: 6vw;
    height: 3.5vh;
    border: 2px solid #ffc54f;
    border-radius: 8px;
    cursor: pointer;
  }

  .signup-button .desktop-text {
    display: none;
  }
  .signup-button .mobile-text {
    display: inline-block;
  }
  .sign-in .form {
    align-items: center;
    width: 30vw;
    justify-content: center;
  }
}
