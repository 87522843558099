:root {
  --additional-text-2-font-family: "Outfit-Regular", Helvetica;
  --additional-text-2-font-size: 14px;
  --additional-text-2-font-style: normal;
  --additional-text-2-font-weight: 400;
  --additional-text-2-letter-spacing: 0px;
  --additional-text-2-line-height: 20px;
  --body-body-2-font-family: "Outfit-Regular", Helvetica;
  --body-body-2-font-size: 16px;
  --body-body-2-font-style: normal;
  --body-body-2-font-weight: 400;
  --body-body-2-letter-spacing: 0.10000000149011612px;
  --body-body-2-line-height: 24px;
  --button-text-button-text-medium-font-family: "Outfit-SemiBold", Helvetica;
  --button-text-button-text-medium-font-size: 16px;
  --button-text-button-text-medium-font-style: normal;
  --button-text-button-text-medium-font-weight: 600;
  --button-text-button-text-medium-letter-spacing: 0px;
  --button-text-button-text-medium-line-height: 20px;
  --button-text-button-text-normal-font-family: "Outfit-SemiBold", Helvetica;
  --button-text-button-text-normal-font-size: 18px;
  --button-text-button-text-normal-font-style: normal;
  --button-text-button-text-normal-font-weight: 600;
  --button-text-button-text-normal-letter-spacing: 0px;
  --button-text-button-text-normal-line-height: 20px;
  --button-text-button-text-small-font-family: "Outfit-SemiBold", Helvetica;
  --button-text-button-text-small-font-size: 14px;
  --button-text-button-text-small-font-style: normal;
  --button-text-button-text-small-font-weight: 600;
  --button-text-button-text-small-letter-spacing: 0px;
  --button-text-button-text-small-line-height: 16px;
  --colorsdarkneutral-100: rgba(156, 159, 167, 1);
  --colorsdarkneutral-200: rgba(101, 106, 120, 1);
  --colorsdarkneutral-300: rgba(59, 64, 78, 1);
  --colorsdarkneutral-400: rgba(38, 42, 54, 1);
  --colorsdarkneutral-500: rgba(27, 30, 40, 1);
  --colorsdarkneutral-700: rgba(16, 18, 24, 1);
  --colorsemerald-200: rgba(220, 247, 239, 1);
  --colorsindigo-100: rgba(238, 240, 255, 1);
  --colorsindigo-500: rgba(122, 134, 248, 1);
  --colorslightgray-100: rgba(246, 246, 246, 1);
  --colorslightgray-300: rgba(227, 228, 229, 1);
  --colorslightgray-700: rgba(73, 75, 81, 1);
  --colorslightgray-800: rgba(27, 30, 37, 1);
  --colorspurple-100: rgba(241, 228, 254, 1);
  --colorspurple-500: rgba(186, 122, 248, 1);
  --colorspurple-700: rgba(112, 73, 149, 1);
  --colorswhitewhite: rgba(255, 255, 255, 1);
  --colorsyellow-500: rgba(255, 197, 79, 1);
  --colorsyellow-700: rgba(153, 118, 47, 1);
  --headlines-h2-font-family: "Outfit-SemiBold", Helvetica;
  --headlines-h2-font-size: 36px;
  --headlines-h2-font-style: normal;
  --headlines-h2-font-weight: 600;
  --headlines-h2-letter-spacing: 0px;
  --headlines-h2-line-height: 44px;
}
