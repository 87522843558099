/** @format */

.onboardoing {
  align-items: center;
  justify-content: center;
  background-color: #1b1e28;
  display: grid;
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/image/Line1.png");
  background-repeat: no-repeat; /* Resmin yinelenmemesini sağlar */
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
}

.onboardoing .frame {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 65vh;
  width: 60vw;
}

.onboardoing .x-v-AI {
  color: transparent;
  font-family: "Outfit-SemiBold", Helvetica;
  font-size: 6vw;
  font-weight: 600;
  margin-top: -1%;
  text-align: center;
  width: 30vw;

}

.onboardoing .text-wrapper {
  color: #ffffff;
}

.onboardoing .span {
  color: #ffc54f;
}

.onboardoing .div {
  color: #ffffff;
  font-family: "Outfit-SemiBold", Helvetica;
  font-size: 3.5vh;
  font-weight: 600;
  line-height: 5vh;
  position: relative;
  text-align: center;
  width: 5 0vw;
}

.onboardoing .button-container {
  display: flex;
  flex-direction: row;
  width: 43vw;
  justify-content: space-between;
  align-items: center; /* İsteğe bağlı olarak yatayda ortala */
}

.onboardoing .login {
  height: 6vh;
  width: 20vw;
  border-radius: 15px;
  background-color: #ffdc95;
}
.onboardoing .signup {
  height: 6vh;
  width: 20vw;
  border-radius: 15px;
  background-color: #1b1e28;
  color: #ffffff;
  border-color: #ffffff;
  border-width: 1px;
}

@media (max-width: 768px) {
  .onboardoing .frame {
    width: 70vw;
  }
  .onboardoing .x-v-AI {
    font-size: 20vw;
    width: 80vw;
  }
  .onboardoing .button-container {
    width: 50vw;
  }
  /* Diğer stilleri burada güncelleyin */
}
